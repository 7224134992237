@media (min-height: 800px) {
  .login-area {
    margin-top: 120px;
  }
}

.login.p-4 {
  margin: 30px;
  border: 1px solid black;
  background-color: hsla(0, 0%, 100%, 0.75);
}

.form-label {
  font-weight: bold;
}

.text-primary {
  font-weight: bold;
}
.login-container {
  height: 100%;
  min-height: 100vh;
}

.login-image-area {
  object-fit: cover !important;

  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.login-area {
  margin-top: 20px !important;
}
.login {
  margin-bottom: 100px !important;
}

.rag-green {
  background-color: lightgreen;
}

.rag-amber {
  background-color: lightcoral;
}

.ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-focus.ag-cell-value {
  width: auto;
}

.ag-header-row.ag-header-row-column {
  justify-content: flex-start;
}

#button {
  height: 20px;
}

.button-wrapper-style {
  position: relative;
  z-index: 1px;
}

.other-content-style {
  position: relative;
  z-index: 2px;
  background-color: red;
  padding: 10px;
}

.ag-theme-alpine.react {
  margin-bottom: 10px;
  width: 100%;
}

.table-container {
  margin-top: 0px !important;
  margin-bottom: 30px;
}

.ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
  background-color: #dde1e9;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
  color: #7b7b91;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.ag-header-cell.ag-floating-filter {
  border-left: 2px solid #dde1e9;
  background-color: #f4f7fe;
}

.plate {
  display: block;
  width: 96px;
  border: 1px solid #008fff;
  border-radius: 2px;
  line-height: 23px;
  font-size: 10px;
  font-weight: 700 !important;
  color: #363636;
  text-align: center;
  position: relative;
  padding-left: 24px;
  background: #fff;
}

.plate span {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  background: #008fff;
  color: #fff;
}

.plateDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 9px;
}
.action_button_container {
  float: right;
}
.reject_modal_info_text {
  text-align: center;
  font-size: 20px
}
.reject_modal_reject_button {
  background-color: #c91432 !important;
  color: white !important;
  width: 90px;
  height: 40px;
  font-size: 14px !important;
}
.reject_modal_close_button {
  background-color: #1F3547 !important;
  color: white !important;
  width: 110px;
  height: 40px;
  font-size: 14px !important;
}
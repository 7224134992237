@media screen and (max-width: 1200px) {
  .login-page-cards {
    width: 100% !important;
  }
  .box {
    margin: 0px 75px;
  }
  .chart-container {
    margin-bottom: 80px;
    border: 0px solid black !important;
    width: 100%;
  }
  .login-page-card {
    text-align: center;
    width: 100% !important;
  }
  .login-page-card-waiting {
    margin-left: 0px !important;
  }
  .login-page-card-defined-plate {
    margin-left: 0px !important;
  }
  .home-component-right-side {
    margin-left: 13px !important;
    margin-bottom: 50px;
    margin-right: 13px !important;
  }
}
@media screen and (max-width: 992px) {
  .counting-box {
    margin: 0px !important;
  }
  .box {
    margin: 10px 0px;
  }
  .home-component-right-side {
    margin-left: 4%;
    max-width: 656px !important;
    margin-right: 30px;
  }
}
@media screen and (max-width: 531px) {
  .home-component-right-side {
    height: 750px !important;
  }
  .progress-container {
    margin-top: 70px !important;
  }
}
.total-number {
  color: #878a99;
  font-size: 20px;
  font-family: Inter, sans-serif;
}

* {
  box-sizing: border-box;
}

.counting-box {
  border-radius: 4px;
  text-align: center;
  height: 150px;
  position: relative;
  overflow: hidden;
  max-width: 750px;
  min-width: 230px !important;
  margin: 20px;
}
.counting-box b {
  display: block;
  font-weight: normal !important;
  font-size: 44px;
  letter-spacing: 6px;
  color: white;
}
.counting-box p {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-size: 14px;
  z-index: 2;
}
.counting-box i {
  font-style: normal;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  color: #423f3949;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-total-service:before {
  font-family: "Font Awesome 5 Free";
  content: "\f7d9";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.icon-waiting-service:before {
  font-family: "Font Awesome 5 Free";
  content: "\f017";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.icon-completed-service:before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.icon-defined-plate:before {
  font-family: "Font Awesome 5 Free";
  content: "\f2c2";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.total-service-box {
  background-color: #4976ba;
}
.waiting-service-box {
  background-color: #c91432;
}
.completed-service-box {
  background-color: #138636;
  margin-top: 5px;
}
.defined-plate-box {
  background-color: #027180;
  margin-top: 5px;
}

.progress-div {
  display: inline-block;
}
.progress-container {
  width: 520px;
  margin-top: 20px;
  border-radius: 3px;
}
.progress-row {
  margin-top: 70px !important;
}
.progress-bar-number {
  color: white;
}
.home-component-right-side {
  height: 325px;
  margin-top: 20px !important;
  background-color: #1f3547;
  border-radius: 3px;
  max-width: 750px !important;

  margin-right: 50px;
}
.progress-bar-number {
  color: #a8bed0;
  font-size: 33px !important;
}

.login-page-card-waiting {
  margin-left: 15px;
}
.login-page-card-defined-plate {
  margin-left: 15px;
}
.progress-bar-title {
  color: #a8bed0;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}
.text-font {
  font-family: "Inter", sans-serif;
}

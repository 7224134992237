.text-center {
  text-align: center;
  color: #878a99;
  font-size: 15px;
  font-family: Inter, sans-serif;
}

.footer-container {
  box-shadow: 0px 13px 24px 0px rgba(156, 149, 149, 0.448);
  height: 55px;
  padding-bottom: 0px;
  bottom: 0;
  padding: 20px calc(1.5rem / 2);
  position: absolute;
  right: 0;
  color: #f3f3f9;
  height: 60px;
  background-color: #f3f3f9;
}

.rag-green {
  background-color: lightgreen;
}

.rag-amber {
  background-color: lightcoral;
}

.ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-focus.ag-cell-value {
  width: auto;
}

.ag-header-row.ag-header-row-column {
  justify-content: flex-start;
}

#button {
  height: 20px;
}

.button-wrapper-style {
  position: relative;
  z-index: 1px;
}

.other-content-style {
  position: relative;
  z-index: 2px;
  background-color: red;
  padding: 10px;
}

.ag-theme-alpine.react {
  margin-bottom: 10px;
  width: 100%;
}

.plates-container {
  display: flex;
  justify-content: center;
  margin-top: 0 !important;
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  width: 0; /* remove scrollbar space */
}

.platesDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 6px;
}
.grid-odd-row {
  background-color: "#F6F8FA";
}
.grid-even-row {
  background-color: "#F0F0F0";
}

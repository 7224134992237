@media screen and (max-width: 531px) {
  .services-component-right-side {
    justify-content: center !important;
  }
  .services-buttons {
    justify-content: center !important;
  }
  .advance-search-input {
    display: none;
  }
  .advance-search-filter-button {
    display: none;
  }
  .advance-search-reset-button {
    display: none;
  }
  .advance-search-mobile-input {
    display: inline-block;
  }
  #incomplated-service,
  #complete-service {
    width: 200px !important;
  }
}
@media screen and (max-width: 636px) {
  .services-component-right-side {
    justify-content: center !important;
  }
  .services-buttons {
    justify-content: center !important;
  }
  #incomplated-service,
  #complete-service {
    width: 200px !important;
  }
}
@media screen and (max-width: 992px) {
  .services-title-div {
    display: none;
  }
  #complete-service:before {
    display: none !important;
  }
  #incomplated-service:before {
    display: none !important;
  }
  .services-component-right-side {
    justify-content: center !important;
  }
}
.services-container {
  max-width: 1400px !important;
}
.service-page-row {
  margin-top: 20px;
}
.services-component-right-side {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}
.services-title-text {
  margin-top: 20px !important;
  padding-left: 0px !important;
  margin-left: 5px !important;
  text-align: left;
  font-size: 14px;
  color: black !important;
}
.services-buttons {
  padding: 10px;
  font-size: 14px;
}
.active {
  background-color: #00008f !important;
}
.navbar-active {
  background-color: white !important;
}
#incomplated-service,
#complete-service {
  width: 220px;
  height: 37px !important;
}
#incomplated-service:before {
  font-family: "Font Awesome 5 Free",serif;
  content: "\f254";
  display: inline-block;
  width: 1.3em;
  margin-right: 5px;
}
#complete-service:before {
  font-family: "Font Awesome 5 Free", serif;
  content: "\f058";
  display: inline-block;
  width: 1.3em;
  margin-right: 5px;
}
.services-tables {
  min-height: 750px;
}
.data-grid-tooltip {
  background-color: white;
  border: 1px solid;
  border-radius: 10px;
  font-family: "Font Awesome 5 Free",serif;
  font-weight: normal;
  font-size: large;
  height: 100px;
  padding: 5px;
  text-align: left;
  width: 400px;
}

/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container{
    .css-1s2u09g-control{
        background-color: $card-bg;
        border-color: $border-color;
        &:hover {
            border-color: $border-color;
        }
    }
    .css-1pahdxg-control{
        background-color: $card-bg;
        border-color: $border-color;
        box-shadow: none;
        &:hover {
            border-color: $border-color !important;
        }
    }
    .css-26l3qy-menu {
        background-color: $dropdown-bg;
        .css-1n7v3ny-option{
            cursor: pointer;
            background-color: $dropdown-link-hover-bg;
            &:active {
                background-color: $dropdown-link-hover-bg;
            }
        }
    }
    .css-1rhbuit-multiValue{
        background-color: var(--#{$variable-prefix}light);
    }
    .css-12jo7m5{
        color:$heading-color;
    }
    .css-6j8wv5-Input,  .css-qc6sy-singleValue{
        color:$heading-color;
    }
}

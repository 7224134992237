nav {
  top: 0;
  box-shadow: 0px 13px 24px 0px rgba(156, 149, 149, 0.448);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 60px;
}
nav .navbar-collapse.collapse {
  display: block;
}
.nav-page-item {
  color: #4b38b3 !important;
  font-size: 1.015625rem;
}
.nav-page-item:hover {
  color: white !important;
  font-size: 1.015625rem;
}
@media (max-width: 991px) {
  nav .navbar-collapse.collapse {
    overflow: hidden;
  }
  nav .navbar-collapse.active {
    background-color: rgb(255, 255, 255);
    height: 100vh !important;
    z-index: 123456;
  }
  li.nav-item {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }
  #i {
    float: left;
  }
  .nav-page-item {
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    margin-top: 0.9;
  }
  .nav-item-container {
    width: 100%;
    padding: 5px;
  }
  .nav-item-text {
    float: left;
  }
  .nav-item-icon {
    float: left;
    margin-top: 2px;
    margin-right: 10px !important;
  }
}
nav .navbar-collapse {
  transition: 0.6s all ease-in-out;
  height: 0 !important;
}

.nav-item {
  margin-left: 40px;
  color: #6d7080;
  font-size: 0.925rem;
  font-family: Inter, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.nav-item:hover {
  cursor: pointer;
  background-color: #00008f !important;
  border-bottom: 3px solid #c91432 !important;
  color: white !important;
}

#i {
  float: left;
}

.logo {
  width: 40px;
  height: 40px;
}

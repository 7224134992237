.rag-green {
  background-color: lightgreen;
}

.rag-amber {
  background-color: lightcoral;
}

.ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-focus.ag-cell-value {
  width: auto;
}

.ag-header-row.ag-header-row-column {
  justify-content: flex-start;
}

.buttons {
  position: "relative";
  z-index: 2;
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
}

.other-content-style {
  position: relative;
  z-index: 2px;
  background-color: red;
  padding: 10px;
}

.ag-theme-alpine.react {
  margin-bottom: 10px;
  width: 100%;
}

.table-container {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.ag-grid-modal {
  height: 300px;
}

.ag-theme-alpine.modal {
  height: 300px;
  margin-bottom: 10px;
}

.service-detail-button-text {
  color: white;
}

.icon-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn {
  margin-right: 5px;
}
.location-check::before {
  font-family: "Font Awesome 5 Free";
  content: "\f11e";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-style: normal;
}

.fa-filter {
  display: none !important;
}
.table-buttons {
  padding: 5px !important;
}

.modal-styles {
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  z-index: 1000;
}
@media screen and (max-width: 531px) {
  .modal-styles {
    width: 350px;
  }
}
.overlay-style {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.btn.btn-primary {
  margin-top: 10px;
  width: 100px;
  float: right;
}

.flex-column.button {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.auth-page-content {
  background-image: "../assets/axa/login_cekici.jpg";
}

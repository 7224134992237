.service-detail-text {
  margin: 10px;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 1.015625rem;
}
.map-switch-button {
  float: right !important;
}

.img-col {
  margin-top: 20px;
  width: 130px;
  height: 120px;
  border-radius: 5px;
}
.images-container {
  min-width: 50%;
  height: 500px;
  overflow-y: scroll;
  padding: 20px;
}
@media screen and (max-width: 531px) {
  .service-detail-text {
    font-family: "Inter", sans-serif;
    font-size: 0.8rem;
    float: right !important;
  }
  .img-col {
    margin-top: 20px;
    width: 140px;
    height: 150px;
    border-radius: 5px;
  }
  .images-container {
   padding-top: 0!important;
  }
}
.padding-20 {
  padding: 20px;
}
.service-status-container {
  min-width: 50%;
  height: 400px;
  margin-bottom: 100px;
  padding: 20px;
}

.icon-area {
  cursor: pointer;
  float: right;
  color: black;
}
.refresh-icon,
.back-icon {
  margin-right: 10px;
}

.back-text,
.refresh-text {
  display: inline-block;
  text-align: left;
}
.map {
  margin: 20px 0px;
}
.bottom-service-detail-text {
  margin: 20px 0px;
  text-align: left;
}

.css-1526ib8,
.Muibox-root,
.MuiBox-root .css-0 {
  width: 130px !important;
  height: 130px !important;
}


.service-detail-container {
  max-width: 100%;
  max-height: 50%;
}
.image-tab-text {
  color: white !important;
}
.image-tab-link {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.image-tab{
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: white;
}